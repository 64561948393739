import * as Sentry from '@sentry/gatsby';

export const sentryConfig = {
  dsn: 'https://c46d14198e554743aa71047a65fd7653@o207017.ingest.sentry.io/5959414',
  integrations: [],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,

  beforeSend(event) {
    // Filter out known non-actionable errors to reduce noise in Sentry dashboard
    if (event.exception && event.exception.values && event.exception.values.length > 0) {
      const errorValues = event.exception.values;
      const errorMessages = errorValues.map((value) => value.value || '');

      // Define patterns to match against error messages
      const patternsToFilter = [
        /Object Not Found Matching Id/i, // Case-insensitive check
        /ChunkLoadError/i, // Case-insensitive check
        /undefined is not an object \(evaluating 'r\["@context"\].toLowerCase\(\)'\)/i, // Case-insensitive check
        /We couldn't load "\/page-data\/\w+\/\d+\.json"/i, // Match any JSON filename
      ];

      // Check if any of the patterns match the error message
      if (
        patternsToFilter.some((pattern) =>
          errorMessages.some((message) => typeof message === 'string' && pattern.test(message))
        )
      ) {
        // For debugging purposes, you can log filtered errors (consider enabling in dev only)
        // console.log('Filtered Sentry error:', errorMessages.join(', '));
        return null; // Return null to ignore the error
      }
    }

    // Return the event for other types of errors
    return event;
  },
};

Sentry.init(sentryConfig);
