import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { IconLink } from './IconLink';
import { Spacer } from './Spacer';
import { Typography } from './Typography';
import { LinkListFragment } from '../types/graphqlTypes';

interface LinkListProps extends LinkListFragment {
  grid?: boolean;
  iconRight?: boolean;
  onClick?: any;
  addBorder?: boolean;
}

interface StyledLinkListProps {
  grid?: boolean;
  gridNarrow?: boolean;
}

interface StyledLinkListContainerProps {
  hasBorder?: boolean;
}

const StyledLinkListContainer = styled(Spacer)<StyledLinkListContainerProps>`
  ${({ theme: { media }, hasBorder }) => css`
    ${hasBorder &&
    css`
      padding: 32px;
      border: solid 1px #e5e5e5;
      // Desktop styles

      @media (min-width: ${media.xl}) {
      }
    `}
  `};
`;

export const StyledLinkList = styled.ul<StyledLinkListProps>`
  ${({ theme: { space, media }, grid, gridNarrow, hasBorder }) => css`
    li {
      margin-top: ${space.sm};
    }

    // Grid

    ${grid &&
    css`
      // Large phone styles

      @media (min-width: ${media.xs}) {
        display: grid;
        grid-column-gap: ${space.md};
        grid-template-columns: ${hasBorder ? '1fr' : 'repeat(2, 1fr)'};
      }

      // Tablet styles

      @media (min-width: ${media.sm}) {
        grid-template-columns: repeat(3, 1fr);
      }

      // Desktop styles

      @media (min-width: ${media.xl}) {
        grid-template-columns: repeat(${gridNarrow ? '3' : '4'}, 1fr);
      }
    `}
  `};
`;

export const LinkList: FC<LinkListProps> = ({
  title,
  smallHeading,
  lead,
  items,
  grid,
  addBorder,
  onClick,
  iconRight = false,
}) => {
  if (items.length < 1) {
    return null;
  }

  const handleClick = (...props) => {
    if (onClick) {
      onClick(...props);
    }
  };

  return (
    <StyledLinkListContainer spacing="half" hasBorder={addBorder}>
      <Spacer spacing="quarter">
        {title && (
          <Typography component="h3" variant="titleLarge">
            {title}
          </Typography>
        )}
        {lead && (
          <Typography component="p" variant="lead">
            {lead}
          </Typography>
        )}
        {smallHeading && (
          <Typography component="h3" variant="titleSmall">
            {smallHeading}
          </Typography>
        )}
      </Spacer>

      <StyledLinkList grid={grid || addBorder} hasBorder={addBorder}>
        {items.map((item, index) => (
          <li key={index}>
            <IconLink
              label={item.link?.name}
              url={item.link?.url}
              arrowLeft={!iconRight}
              arrowRight={iconRight}
              eventClickSection={smallHeading || title}
              onClick={handleClick}
            />
          </li>
        ))}
      </StyledLinkList>
    </StyledLinkListContainer>
  );
};
