import React, { createContext, useContext, useEffect, useState } from 'react';
import { PageProps } from 'gatsby';

interface NavigationContextType {
  location: PageProps['location'];
}

const NavigationContext = createContext<NavigationContextType | null>(null);

export const NavigationProvider: React.FC<{ location: PageProps['location']; children: React.ReactNode }> = ({
  location,
  children,
}) => {
  const [locationState, setLocationState] = useState<PageProps['location']>(location);

  useEffect(() => {
    setLocationState(location);
  }, [location]);

  return <NavigationContext.Provider value={{ location: locationState }}>{children}</NavigationContext.Provider>;
};

export const useNavigation = (): NavigationContextType => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
